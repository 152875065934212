import { isObject } from '@shein/common-function'
import schttp from 'public/src/services/schttp'
import { SILogger } from 'public/src/pages/common/monitor/index.js'

/**
通过订单号获取编辑地址的条件配置
* @param {Object} params[必填] 请求参数
* @param {Object} params.orderInfo[必填] billnoList: 订单集合
* @param {string} sceneType.sceneType[选填] sceneType: 场景类型
*/
export const getEditAddressConditionConfig = async ({ billnoList = [], sceneType = '' } = {}) => {
  if (!billnoList.length) return []

  // 根据订单号请求是否可以编辑地址信息
  let res = await schttp({
    url: '/api/orders/base/queryCouldModifyAddress/query',
    method: 'POST',
    data: {
      billno_list: billnoList,
      source: sceneType
    }
  })?.catch(() => {})

  return res?.info?.order_list || []
}

/**
获取批量编辑地址信息
* @param {Object} params[必填] 请求参数
* @param {Object} params.billno[必填] billno: 订单号
*/
export const getBatchEditAddressInfo = async ({ billno = '', sceneType = '', orderStatus = '' } = {}) => {
  if (!billno) return {}

  // 根据订单号请求是否可以编辑地址信息
  let res = await schttp({
    url: `/api/orders/query/batchModifyAddress/get`,
    method: 'POST',
    data: {
      billno,
      sceneType,
      orderStatus
    }
  })?.catch(() => {})

  return res?.info || {}
}

/**
获取批量编辑地址信息
* @param {Object} params[必填] 请求参数
* @param {Object} params.editAddressNewConfig[必填] 编辑地址配置
* @param {Object} params.language[必填] 多语言配置
*/
export const getSuccessHandleTipText = (editAddressNewConfig = {}, language = {}) => {
  let { editable_type, combined_shipped_flag } = editAddressNewConfig
  let allShippedTextList = ['all_shipped']

  if (combined_shipped_flag && allShippedTextList.includes(editable_type)) {
    let languageMap = {
      1: 'SHEIN_KEY_PWA_29033',
      default: 'SHEIN_KEY_PWA_30541'
    }
    let languageKey = languageMap[combined_shipped_flag] || languageMap['default']
    return language[languageKey] || ''
  }

  let editableTypeMap = {
    'partly_shipped_after_editable': 'SHEIN_KEY_PWA_21428',
    'partly_shipped_before_editable': 'SHEIN_KEY_PWA_21427',
    'partly_shipped_all_editable': 'SHEIN_KEY_PWA_21426',
    'unshipped': '',
    'unpaid': ''
  }

  let languageKey = editableTypeMap[editable_type] || ''
  return language[languageKey] || ''
}

/**
获取批量编辑地址信息
* @param {Object} params[必填] 请求参数
* @param {Object} params.editAddressNewConfig[必填] 编辑地址配置
* @param {Object} params.language[必填] 多语言配置
*/
export const getErrorHandleTipText = (editAddressNewConfig = {}, language = {}) => {
  let { editable_type, combined_shipped_flag } = editAddressNewConfig
  let allShippedTextList = ['all_shipped']

  if (combined_shipped_flag && allShippedTextList.includes(editable_type)) {
    return language['SHEIN_KEY_PWA_21424'] || ''
  }

  return ''
}

/**
是否是已支付关联订单号取值
* @param {Object} params[必填] 请求参数
* @param {Object} params.editAddressNewConfig[必填] 编辑地址配置
* @param {Object} params.order[必填] 订单信息
*/
// 
export const isRelationBillno = (editAddressNewConfig = {}) => {
  let { billno = '', sub_billno = '', editable_type = '' } = editAddressNewConfig || {}
  let editableTypeList = ['unpaid', 'all_shipped', 'partly_shipped_after_editable']

  return !editableTypeList.includes(editable_type) ? sub_billno : billno
}

/**
 * 推荐弹框点击交互事件数据采集
 * @param {Object} orderInfo[必填] 订单信息
 * @param {Object} checkOrderAddressInfo[必填] 推荐地址信息
 * @param {Object} order_modify_result_type[必填] 订单修改结果 0: 默认值 1：修改成功 2：修改失败 3：关闭弹窗
 * @param {Object} pop_recommend_result_type[必填] 推荐弹窗操作结果 1: 关闭推荐弹窗 2:使用原订单地址（不做修改使用Use This）3:使用推荐弹窗推荐地址（use this）4：跳地址表单修改
*/
export const sendSyncAddressRecommendResult = ({ orderInfo, checkOrderAddressInfo, order_modify_result, pop_recommend_result }) => {
  let { billno, address_id } = orderInfo || {}
  let { check_type: pop_recommend_rule, display_type: pop_recommend_type, requestId: request_id } = checkOrderAddressInfo || {}

  let params =  {
    request_id,
    pop_recommend_type,
    pop_recommend_rule,
    distribution_type: 1,
    pop_recommend_address: 1,
    order_modify_result,
    pop_recommend_result,
    order_no: billno,
    address_id
  }

  schttp({
    url: `/api/orders/base/querySyncAddressRecommendResult/query`,
    method: 'POST',
    data: params
  })?.catch(() => {})
}

// 获取地址提交前后的埋点数据
export const getModifyAddressInfoEvt = ({
  oldAddressInfo = {},
  newAddressInfo = {},
  disabledAddressInfo = [],
  options = {}
}) => {
  // 先格式化一下置灰数据字段[部分主要字段], 使字段对应上
  let partOldAddressInfo = {
    fname: oldAddressInfo?.fname || '',
    lname: oldAddressInfo?.lname || '',
    postcode: oldAddressInfo?.postcode || '',
    address1: oldAddressInfo?.address1 || '',
    address2: oldAddressInfo?.address2 || '',
    country: oldAddressInfo?.countryId || oldAddressInfo?.country_id || '',
    state: oldAddressInfo?.state || '',
    city: oldAddressInfo?.city || '',
    street: oldAddressInfo?.street || '',
    district: oldAddressInfo?.district || '',
    middleName: oldAddressInfo?.middleName || '',
    fatherName: oldAddressInfo?.fatherName || '',
    englishName: oldAddressInfo?.englishName || '',
    taxNumber: oldAddressInfo?.taxNumber || '',
    tel: oldAddressInfo?.tel || '',
    standbyTel: oldAddressInfo?.standbyTel || ''
  }
  let partNewAddressInfo = {
    fname: newAddressInfo?.fname || '',
    lname: newAddressInfo?.lname || '',
    postcode: newAddressInfo?.postcode || '',
    address1: newAddressInfo?.address1 || '',
    address2: newAddressInfo?.address2 || '',
    country: newAddressInfo?.countryId || newAddressInfo?.country_id || '',
    state: newAddressInfo?.state || '',
    city: newAddressInfo?.city || '',
    street: newAddressInfo?.street || '',
    district: newAddressInfo?.district || '',
    middleName: newAddressInfo?.middle_name || '',
    fatherName: newAddressInfo?.father_name || '',
    englishName: newAddressInfo?.english_name || '',
    taxNumber: newAddressInfo?.tax_number || '',
    tel: newAddressInfo?.tel || '',
    standbyTel: newAddressInfo?.standby_tel || ''
  }
  
  // 先判断一下新旧置灰字段的值是否一致
  let isModifyDisabledKey = false
  disabledAddressInfo?.forEach(key => {
    if (partOldAddressInfo[key] !== partNewAddressInfo[key]) {
      isModifyDisabledKey = true
    }
  })

  let newAddressInfoStr = Object?.values(partNewAddressInfo)?.join('##')
  let oldAddressInfoStr = Object?.values(partOldAddressInfo)?.join('##')
  let disabledAddressInfoStr = disabledAddressInfo?.join('##')

  if (isModifyDisabledKey) {
    SILogger.logWarn({
      tag: options.tag,
      message: options.message,
      params: {
        disabledAddressInfoStr,
        oldAddressInfoStr,
        newAddressInfoStr,
        storeData: {
          scene: options.scene,
          billno: options.billno || '',
          status: options.status || ''
        }
      }
    })
  }
}

// 判断传入的值是否为空
export const isEmptyValue = (value) => {
  if (value === undefined || value === null) {
    return true
  }
  if (Array.isArray(value) && !value.length) {
    return true
  }
  if (isObject(value) && Object.keys(value).length == 0) {
    return true
  }
  if (typeof value === 'string' && !value) {
    return true
  }
  return false
}
