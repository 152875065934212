<template>
  <div>
    <s-dialog
      v-model:visible="showRetry"
      :append-to-body="true"
      :close-on-click-modal="false"
      :show-close="true"
      @close-from-icon="closeRetry"
    >
      <div class="retry-text">
        {{ language?.SHEIN_KEY_PWA_34859 }}
      </div>
      <template #footer>
        <s-button-group hor>
          <s-button-group-item
            :type="['H72PX']"
            @click="closeRetry"
          >
            {{ language?.SHEIN_KEY_PWA_26649 }}
          </s-button-group-item>
          <s-button-group-item
            :type="['primary', 'H72PX']"
            @click="handleRetry"
          >
            {{ language?.SHEIN_KEY_PWA_30659 }}
          </s-button-group-item>
        </s-button-group>
      </template>
    </s-dialog>
    <s-dialog
      v-model:visible="showRetryResult"
      :append-to-body="true"
      :close-on-click-modal="false"
      :show-close="true"
      @close-from-icon="closeRetryResult"
    >
      <div class="retry-text">
        {{ language?.SHEIN_KEY_PWA_34980 }}
      </div>
      <template #footer>
        <s-button
          :type="['primary', 'H72PX']"
          :width="'100%'"
          @click="handleRetryResult"
        >
          {{ language?.SHEIN_KEY_PWA_34860 }}
        </s-button>
      </template>
    </s-dialog>
  </div>
</template>
<script setup>
import { SButton } from '@shein-aidc/sui-button/mobile'
import { SDialog } from '@shein-aidc/sui-dialog/mobile'
import { SButtonGroup } from '@shein-aidc/sui-button-group/mobile'
import { SButtonGroupItem } from '@shein-aidc/sui-button-group-item/mobile'
import { defineExpose, ref, toRefs, defineEmits, defineProps } from 'vue'
import { daEventCenter } from 'public/src/services/eventCenter/index'

daEventCenter.addSubscriber({ modulecode: '2-46' })
const props = defineProps({
  language: {
    type: Object,
    default: () => ({})
  }
})
const { language } = toRefs(props)
const emits = defineEmits([
  'newAddressShippingEdit',
  'retryConfirm',
  'addressEdit',
  'addressShippingEdit',
  'postStroeAddressData'
])

// * 重试地址编辑次数
let retryAddressNum = ref(0)
// * 展示重试弹窗
let showRetry = ref(false)
// * 展示重试结果弹窗
let showRetryResult = ref(false)
// * 重试数据
const retryData = ref({})
// * 特殊场景 没有打开地址表单直接调用保存接口的场景
const specialSituations = ['customsInterceptionAddressBook']
// * 重试结果弹窗关闭不需要清空数据场景
const saveSpecialSituations = ['recommendUseThis']

// * 统一处理埋点
const sendAddressAnalysis = type => {
  const extraData = {
    orderid: retryData.value?.orderInfo?.billno || '',
    modify_type:
      retryData.value?.batchModifyAddressParams?.modify_type ||
      retryData.value?.batchModifyAddressParams?.modify_type == 0
        ? retryData.value?.batchModifyAddressParams?.modify_type
        : '-',
    cross_type:
      retryData.value?.batchModifyAddressParams?.cross_type ||
      retryData.value?.batchModifyAddressParams?.cross_type == 0
        ? retryData.value?.batchModifyAddressParams?.cross_type
        : '-'
  }
  if (type === 'expose') {
    daEventCenter.triggerNotice({
      daId: '2-46-1',
      extraData
    })
  } else {
    daEventCenter.triggerNotice({
      daId: '2-46-2',
      extraData: {
        ...extraData,
        button: type === 'click' ? 'retry' : 'close'
      }
    })
  }
}

// * 初始化地址重试数据
const handleRetryAddressInit = data => {
  retryAddressNum.value = retryAddressNum.value + 1
  retryData.value = data
  if (retryAddressNum.value > 3) {
    showRetryResult.value = true
  } else {
    sendAddressAnalysis('expose')
    showRetry.value = true
  }
}

// * 重试计数清空
const clearRetryNum = () => {
  retryAddressNum.value = 0
}

// * 关闭重试弹窗
const closeRetry = () => {
  showRetry.value = false
  sendAddressAnalysis('close')
  if (specialSituations.includes(retryData.value?.edit_type)) {
    clearRetryNum()
  }
}
// * 继续重试逻辑
const handleRetry = () => {
  showRetry.value = false
  sendAddressAnalysis('click')
  if (!retryData.value?.callBackFtnName || !retryData.value?.retryAddressData)
    return
  emits(
    retryData.value?.callBackFtnName,
    retryData.value?.retryAddressData,
    retryData.value?.edit_type
  )
}

// * 关闭重试结果弹窗
const closeRetryResult = () => {
  showRetryResult.value = false
  if (specialSituations.includes(retryData.value?.edit_type)) {
    clearRetryNum()
  }
}
// * 重试结果弹窗确认
const handleRetryResult = () => {
  showRetryResult.value = false
  if (!saveSpecialSituations.includes(retryData.value?.edit_type)) {
    clearRetryNum()
  }
  emits(
    'retryConfirm',
    retryData.value?.addressComponentName,
    retryData.value?.edit_type
  )
}

defineExpose({
  handleRetryAddressInit,
  clearRetryNum
})
</script>
<style lang="less" scoped>
.retry-text {
  color: @sui_color_gray_dark1;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
}
</style>
